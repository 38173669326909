import './header.scss';

export const Header = () => {

  const downloadCV = () => {
    const aTag = document.createElement("a");
    aTag.href = "/pdf/CV.pdf";
    aTag.setAttribute('download', 'Kevin CV');
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  }
  return (
    <header className="d-middle w-100 mb-4 mb-md-0">
      <nav className="d-middle j-between w-100 h-100 flex-wrap container">
        <div className="d-middle-center mb-3 mb-md-0 h-100 cont-logo">
          <a className="f-28 f-semibold text-general logo" href="#banner">
            <span className="text-second">{"{"}</span>
            {" Kwmejia "}
            <span className="text-second">{"}"}</span>
          </a>
        </div>

        <div className="d-middle gap-4 flex-wrap nav-right">
          <div className="d-flex gap-3 items-nav">
            <a href="#" className="text-general f-22">Inicio</a>
            <a href="#skills" className="text-general f-22">Habilidades</a>
            <a href="#projects" className="text-general f-22">Proyectos</a>
          </div>

          <div className="h-100 d-middle">
            <a onClick={downloadCV} className="f-semibold buttom-cv d-middle f-22">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Download CV
            </a>
          </div>
        </div>
      </nav>
    </header>
  )
}
