import React from 'react'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Kevin } from './pages/Kevin';

const App = () => {
  library.add(fas);
  return (
    <>
      <Kevin />
    </>
  )
}
export default App;