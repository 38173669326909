
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import './projects.scss'
import { tecnologies } from "./technologies";


export const Projects = () => {
  const projects = [
    {
      title: "IPSEL SAS",
      description: "Página web para empresa de servicios tecnológicos Ipsel SAS.",
      imgUrl: "/img/ipsel.png",
      url: "https://ipsel.net.co/",
      tecnologies: [tecnologies.PHP, tecnologies.Bootstrap, tecnologies.CSS3, tecnologies.MySQL, tecnologies.JavaScript]
    },
    {
      title: "ATTI SAS",
      description: "Página Web para empresa de telecomunicaciones ATTI SAS.",
      imgUrl: "/img/atti.png",
      url: "https://www.atti.net.co/",
      tecnologies: [tecnologies.PHP, tecnologies.Bootstrap, tecnologies.CSS3, tecnologies.MySQL, tecnologies.JavaScript]
    },
    {
      title: "Biggibot",
      description: "Tienda tecnológica virtual Biggibot, equipos de red y software.",
      imgUrl: "/img/biggibot.png",
      url: "https://biggibot.net.co/",
      tecnologies: [tecnologies.PHP, tecnologies.Bootstrap, tecnologies.CSS3, tecnologies.MySQL, tecnologies.JavaScript]
    },
    {
      title: "Silvia Diaz",
      description: "Página Web para la implementación de una tienda virtual de ropa deportiva.",
      imgUrl: "/img/silviadiaz.png",
      url: "https://silviadiazsd.github.io/Inicio/",
      tecnologies: [tecnologies.Bootstrap, tecnologies.CSS3, tecnologies.Html5, tecnologies.JavaScript],
      git: "https://github.com/kwmejia/SHOP_SilviaDiaz"
    },
    {
      title: "Map App - MapBox",
      description: "Mapa utilizando map box, rutas, puntos y polilineas",
      imgUrl: "/img/maps.png",
      url: "https://maps.kevinmejia.com.co/",
      tecnologies: [tecnologies.CSS3, tecnologies.Bootstrap, tecnologies.React, tecnologies.Typescript],
      git: "https://github.com/kwmejia/Map-app-mapbox"
    },
    {
      title: "Pokemon Game Test",
      description: "Design & Development",
      tecnologies: [tecnologies.Sass, tecnologies.Vue, tecnologies.JavaScript],
      imgUrl: "/img/pokemon.png",
      git: "https://github.com/kwmejia/PokemonGame",
      url: "https://pokemongame.kevinmejia.com.co/",
    },
  ];

  const projectsP2 = [
    {
      title: "Pomodoro",
      description: "Design & Development",
      imgUrl: "/img/pomodoro.png",
      url: "https://www.pomodoro.kevinmejia.com.co/",
      tecnologies: [tecnologies.CSS3, tecnologies.Html5, tecnologies.JavaScript],
      git: "https://github.com/kwmejia/CSS-Animations/tree/main/Pomodoro"
    },
    {
      title: "Calculadora",
      description: "Calculadora utilizando tecnologías como JavaScript con un diseño agradable.",
      imgUrl: "/img/calculadora.png",
      url: "https://calculadora.kevinmejia.com.co/",
      git: "https://github.com/kwmejia/CSS-Animations/tree/main/Calculadora",
      tecnologies: [tecnologies.Html5, tecnologies.CSS3, tecnologies.JavaScript]
    },
    {
      title: "Gestor de Proyectos de Grado",
      description: "Proyecto enfocado al manejo administrativo y final de proyectos de grado en las Unidades Tecnologicas de Santander.",
      imgUrl: "/img/gestoruts.png",
      git: "https://github.com/kwmejia/GestionProyectosGradoUts",
      tecnologies: [tecnologies.React, tecnologies.Typescript, tecnologies.Sass, tecnologies.MySQL, tecnologies.NodeJs]
    },
    {
      title: "Red Social GBP",
      description: "Red social para al empresa de desarrollo Grupo Bien Pensado.",
      imgUrl: "/img/redsocial.png",
      git: "https://github.com/kwmejia/GBP_Red_Social",
      tecnologies: [tecnologies.PHP, tecnologies.Laravel, tecnologies.CSS3, tecnologies.MySQL, tecnologies.JavaScript]
    },
    {
      title: "Sistema para veterinaria",
      description: "Sistema para una veterinaria con cuentas personales y asignación de pacientes.",
      imgUrl: "/img/veterinaria.png",
      tecnologies: [tecnologies.React, tecnologies.Sass, tecnologies.NodeJs, tecnologies.MongoDB],
      git: "https://github.com/kwmejia/APV_MERN_frontend"
    },
    {
      title: "Página Web Para un Cantante",
      description: "Página Web Para un Cantante",
      imgUrl: "/img/cantante.png",
      tecnologies: [tecnologies.CSS3, tecnologies.Html5, tecnologies.JavaScript, tecnologies.PHP, tecnologies.MySQL],
      git: "https://github.com/kwmejia/KhanPhp"
    },

  ];

  const projectsP3 = [
    {
      title: "Sistema de rutas y transporte",
      description: "Sistema de rutas y transporte utilizando la api de Google maps.",
      tecnologies: [tecnologies.CSS3, tecnologies.Vue, tecnologies.JavaScript, tecnologies.Laravel, tecnologies.MySQL],
      imgUrl: "/img/transport.png",
      git: "https://github.com/kwmejia/Transporte_frontend",
    },

    {
      title: "Buscador de peliculas",
      description: "Buscador de peliculas.",
      imgUrl: "/img/movies.png",
      git: "https://github.com/kwmejia/MoviesApi-React",
      tecnologies: [tecnologies.React, tecnologies.CSS3, tecnologies.JavaScript]
    },

  ];

  return (
    <section id="projects" className="my-5">
      <Container>
        <Row>
          <Col>
            <h2 className="text-center text-general mb-4">Algunos de mis Proyectos</h2>

            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                <Nav.Item>
                  <Nav.Link eventKey="first">Página 1</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Página 2</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Página 3</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content id="slideInUp">
                <Tab.Pane eventKey="first">
                  <Row>
                    {
                      projects.map((project, index) => {
                        return (
                          <ProjectCard
                            key={index}
                            {...project}
                          />
                        )
                      })
                    }
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row>
                    {
                      projectsP2.map((project, index) => {
                        return (
                          <ProjectCard
                            key={index}
                            {...project}
                          />
                        )
                      })
                    }
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Row>
                    {
                      projectsP3.map((project, index) => {
                        return (
                          <ProjectCard
                            key={index}
                            {...project}
                          />
                        )
                      })
                    }
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>

          </Col>
        </Row>
      </Container>
    </section>
  )
}
