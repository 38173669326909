import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Col, Container, Row } from 'reactstrap';
import skills from './dataSkills';
import './skills.scss';

export const Skills = () => {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skills my-5" id="skills">
      <Container>
        <Row className="cont-skills">
          <Col>
            <div className="skills-bx mb-4">
              <h2 className="text-center text-general"> Skills </h2>
              <p className="text-muted2 px-3 px-md-0 text-center fs-5">A continuación, podrás ver un resumen de mis habilidades como desarrollador.</p>
            </div>

            <Carousel responsive={responsive} infinite={true} className="skill-slider my-5">
              {skills.map(skill => (

                <div className="item" key={skill.id}>
                  <span className="f-bold f-12 text-second m-0">({skill.valueText})</span>

                  <div className="skill-item d-middle-center">
                    <div className="outer">
                      <div className="inner d-middle-center">
                        <div className="d-middle-center">
                          <img src={skill.imgSrc} width={80} alt="imgTecnology" className={skill.rounded ? "rounded-circle img-fluid" : "img-fluid"} />
                        </div>
                      </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
                      <defs>
                        <linearGradient id="GradientColor">
                          <stop offset="0%" stop-color="#003140" />
                          <stop offset="100%" stop-color="#2196f3" />
                        </linearGradient>
                      </defs>
                      <circle cx="80" cy="80" r="70" stroke-linecap="round" style={{ strokeDashoffset: skill.value }} />
                    </svg>
                  </div>
                  <h5 className="text-general f-semibold m-0">{skill.name}</h5>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  )
}


