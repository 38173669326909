import { Header, Banner } from "../components"
import { Projects } from "../components/projects/Projects"
import { Skills } from "../components/skills/Skills"

export const Kevin = () => {
  return (
    <main className="custom-scroll fadeIn">
      <Header />
      <div className="main-content">
        <Banner />
        <Skills />
        <Projects />
      </div>
    </main>
  )
}
