import { useEffect } from 'react';
import { useState } from 'react';
import './banner.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(200);
  const [index, setIndex] = useState(1);
  const toRotate = ["FronTend Developer", "Backend Developer", "UI/UX Designer"];
  const period = 1000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(200);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <>
      <div className="banner-container container-fluid d-middle-center flex-wrap" id="banner">
        <div className="row d-middle j-around w-100">
          <div className="d-flex flex-column col-12 col-md-6 mb-4 mb-md-0 ps-5">
            <div className="welcome-container f-18">
              Bienvenido a mi portafolio
            </div>
            <p className=" f-semibold text-general name-text">Hola! Soy Kevin </p>
            <nav className="nav-social-net d-flex gap-3">
              <a href="https://www.linkedin.com/in/kevin-wifred-mej%C3%ADa-torres-027a97241/" target='_blank'>
                <i class="fab fa-linkedin"></i>
              </a>
              <a href="https://github.com/kwmejia/" target='_blank'>
                <i class="fab fa-github"></i>
              </a>
            </nav>
            <p className="text-second  f-semibold wrap">{text}</p>

            <div className="mt-2 w-25 w-100 description">
              <p className="f-18">¡Hola! Soy Kevin Mejía, un apasionado desarrollador web con una pasión por crear experiencias web impresionantes y funcionales. Con 3 de experiencia en programación, me enorgullece ofrecer soluciones creativas y eficientes para los desafíos de desarrollo.</p>
            </div>
          </div>
          <div className="cont-image col-12 col-md-6 d-middle-center">
            <img src="/img/gifDeveloper.gif" alt="gifDveloper" className="rounded-circle" />
          </div>
        </div>
      </div>
    </>
  );
}
