export const tecnologies = {
  Html5: { id: 1, name: 'Html5', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/c/c5/Html5_dise%C3%B1o_web.png', },
  CSS3: { id: 2, name: 'CSS3', imgSrc: 'https://cdn.pixabay.com/photo/2017/08/05/11/16/logo-2582747_1280.png' },
  JavaScript: { id: 3, name: 'JavaScript', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1200px-Unofficial_JavaScript_logo_2.svg.png', rounded: true },
  Typescript: { id: 4, name: 'Typescript', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg', rounded: true },
  React: { id: 5, name: 'React', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/React.svg/800px-React.svg.png' },
  Vue: { id: 7, name: 'Vue', imgSrc: 'https://img.icons8.com/color/512/vue-js.png' },
  Sass: { id: 6, name: 'Sass', imgSrc: 'https://avatars.githubusercontent.com/u/317889?s=200&v=4' },
  Bootstrap: { id: 15, name: 'Bootstrap', imgSrc: 'https://avatars.githubusercontent.com/u/2918581?s=200&v=4', rounded: true },
  Git: { id: 16, name: 'Git', imgSrc: 'https://avatars.githubusercontent.com/u/18133?s=200&v=4' },
  NodeJs: { id: 10, name: 'NodeJs', imgSrc: 'https://pluralsight2.imgix.net/paths/images/nodejs-45adbe594d.png', rounded: true },
  PHP: { id: 11, name: 'PHP', imgSrc: 'https://www.php.net/images/meta-image.png', rounded: true },
  Laravel: { id: 12, name: 'Laravel', imgSrc: 'https://blog.pleets.org/img/articles/laravel-icon.png' },
  Java: { id: 13, name: 'Java', imgSrc: 'https://www.manualweb.net/img/logos/java.png' },
  Spring: { id: 14, name: 'Spring', imgSrc: 'https://www.armadilloamarillo.com/wp-content/uploads/spring-boot-ok.png' },
  MySQL: { rounded: true, id: 21, name: 'MySQL', imgSrc: 'https://cdn-icons-png.flaticon.com/512/5968/5968313.png' },
  PostgreSQL: { id: 22, name: 'PostgreSQL', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/1200px-Postgresql_elephant.svg.png' },
  MongoDB: { id: 23, name: 'MongoDB', imgSrc: 'https://miro.medium.com/max/512/1*doAg1_fMQKWFoub-6gwUiQ.png', rounded: true },
  Firebase: { id: 28, name: 'Firebase', imgSrc: 'https://cdn.dribbble.com/users/528264/screenshots/3140440/media/5f34fd1aa2ebfaf2cd548bafeb021c8f.png?compress=1&resize=400x300&vertical=top' },
}