import { Col } from "react-bootstrap";
import './projects.scss'

export const ProjectCard = ({ title, description, imgUrl, url, tecnologies, git }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div href={url} className="proj-imgbx">
        <img src={imgUrl} className="img-proj" />
        <div className="proj-txtx gap-4">
          <div className="d-flex flex-column text-center align-items-center">
            <h4>{title}</h4>
            <span>{description}</span>
            <div className="mt-3 d-flex gap-3">
              {url && <a href={url} target="_blank" className="btn-show-more ">Ver sitio web</a>}
              {git && <a href={git} target="_blank" className="btn-show-more ">Ver Código</a>}
            </div>
          </div>
          <div className="">
            <h5>Tecnologías</h5>
            <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
              {tecnologies && tecnologies.map(tecnologie => (
                <div className="tecnologie-cont">
                  <img src={tecnologie.imgSrc} key={tecnologie.id} height={40} width={40} className={tecnologie.rounded ? "rounded-circle bg-white" : ""} />
                  <p >{tecnologie.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}