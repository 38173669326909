const skills = [
  { id: 1, name: 'Html5', value: 80, valueText: '95%', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/c/c5/Html5_dise%C3%B1o_web.png', },
  { id: 2, name: 'CSS3', value: 85, valueText: '90%', imgSrc: 'https://cdn.pixabay.com/photo/2017/08/05/11/16/logo-2582747_1280.png' },
  { id: 3, name: 'JavaScript', value: 95, valueText: '85%', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1200px-Unofficial_JavaScript_logo_2.svg.png', rounded: true },
  { id: 4, name: 'Typescript', value: 150, valueText: '70%', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg', rounded: true },
  { id: 5, name: 'React', value: 100, valueText: '80%', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/React.svg/800px-React.svg.png' },
  { id: 7, name: 'Vue', value: 85, valueText: '90%', imgSrc: 'https://img.icons8.com/color/512/vue-js.png' },
  { id: 6, name: 'Sass', value: 85, valueText: '90%', imgSrc: 'https://avatars.githubusercontent.com/u/317889?s=200&v=4' },
  { id: 15, name: 'Bootstrap', value: 80, valueText: '95%', imgSrc: 'https://avatars.githubusercontent.com/u/2918581?s=200&v=4', rounded: true },
  { id: 16, name: 'Git', value: 80, valueText: '95%', imgSrc: 'https://avatars.githubusercontent.com/u/18133?s=200&v=4' },
  { id: 10, name: 'NodeJs', value: 150, valueText: '70%', imgSrc: 'https://pluralsight2.imgix.net/paths/images/nodejs-45adbe594d.png' },
  { id: 11, name: 'PHP', value: 95, valueText: '85%', imgSrc: 'https://www.php.net/images/meta-image.png', rounded: true },
  { id: 12, name: 'Laravel', value: 150, valueText: '70%', imgSrc: 'https://blog.pleets.org/img/articles/laravel-icon.png' },
  { id: 13, name: 'Java', value: 95, valueText: '85%', imgSrc: 'https://www.manualweb.net/img/logos/java.png' },
  { id: 14, name: 'Spring Boot', value: 165, valueText: '60%', imgSrc: 'https://www.armadilloamarillo.com/wp-content/uploads/spring-boot-ok.png' },
  { id: 21, name: 'MySQL', value: 95, valueText: '85%', imgSrc: 'https://cdn-icons-png.flaticon.com/512/5968/5968313.png' },
  { id: 22, name: 'PostgreSQL', value: 95, valueText: '85%', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/1200px-Postgresql_elephant.svg.png' },
  { id: 23, name: 'MongoDB', value: 95, valueText: '85%', imgSrc: 'https://miro.medium.com/max/512/1*doAg1_fMQKWFoub-6gwUiQ.png' },
  { id: 28, name: 'Firebase', value: 165, valueText: '60%', imgSrc: 'https://cdn.dribbble.com/users/528264/screenshots/3140440/media/5f34fd1aa2ebfaf2cd548bafeb021c8f.png?compress=1&resize=400x300&vertical=top' },
];

export default skills;